<template>
  <div>
    <a-descriptions>
      <a-descriptions-item label="法人">{{
        company.legalEntity
      }}</a-descriptions-item>
      <a-descriptions-item label="注册资本">{{
        company.capital
      }}</a-descriptions-item>
      <a-descriptions-item label="企业注册时间">{{
        company.registerDate
      }}</a-descriptions-item>
      <a-descriptions-item label="企业经营状态">{{
        company.manageStatus
      }}</a-descriptions-item>
      <a-descriptions-item label="工商注册号">{{
        company.companyRegistrationNo
      }}</a-descriptions-item>
      <a-descriptions-item label="统一信用代码">{{
        company.creditCode
      }}</a-descriptions-item>
      <a-descriptions-item label="登记机关">{{
        company.registerOffice
      }}</a-descriptions-item>
      <a-descriptions-item label="营业开始日期">{{
        company.termStart
      }}</a-descriptions-item>
      <a-descriptions-item label="营业期限">{{
        company.termEnd
      }}</a-descriptions-item>
      <a-descriptions-item label="核准日期">{{
        company.checkDate
      }}</a-descriptions-item>
      <a-descriptions-item label="注册地址">{{
        company.registerAddr
      }}</a-descriptions-item>
      <a-descriptions-item label="省份">{{
        company.province
      }}</a-descriptions-item>
      <a-descriptions-item label="联系人邮箱">{{
        company.email
      }}</a-descriptions-item>
      <a-descriptions-item label="联系人手机号">{{
        company.mobile
      }}</a-descriptions-item>
      <a-descriptions-item label="最近的招聘时间">{{
        company.qmpLatestRecruitDate
      }}</a-descriptions-item>
      <a-descriptions-item label="高新认证年份">{{
        company.certifyYear
      }}</a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { getCompanyInfo } from "@/api/company";

export default {
  name: "CompanyBaseInfo",
  data() {
    return {
      company: {},
    };
  },
  created() {
    getCompanyInfo({
      companyId: this.$route.params.companyId,
    }).then((res) => {
      if (res.code === 0) {
        this.company = res.data;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.company-info {
  background-color: white;
  padding: 20px;
}
</style>